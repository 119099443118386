<template>
  <!-- 支付宝支付界面 -->
  <div></div>
</template>
<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  created() {

    var form = this.$route.query.data;
    const div = document.createElement("div");
    div.innerHTML = form;
    document.body.appendChild(div);
    document.forms[0].submit();
  },
  methods: {},
};
</script>
